import React, { useState, useEffect } from 'react';
import { query, collection, onSnapshot } from 'firebase/firestore';
import db from '../../firebase';
import {Image} from 'react-bootstrap';

function CodesList() {
  const [codes, setCodes] = useState([])
  const [loading, setLoading] = useState(true)
  
  useEffect (() => {
    onSnapshot(query(collection(db, `/codes`)), (snapshot) => {
      setCodes(snapshot.docs.map(doc => ({...doc.data(), id: doc.id})))
    });
    setLoading(false)
  }, [])
  
  if (!loading) {
    return (
      <div>
        {codes.map((code) => (
          <div className='d-flex h-100 align-items-center char-stat__bg mb-1'>
            <h5 className='ardela ml-2'>{code.title}</h5>
            <div className='d-flex p-2 my-1'>
              {code.rewards.map(reward => (
                reward.value&&(<div className='d-flex h-100 align-items-center'>
                  <Image className='reward-img mr-1' src={`https://firebasestorage.googleapis.com/v0/b/sfduel-74b69.appspot.com/o/rewards%2F${reward.thing}.png?alt=media`} />
                  <h6 className='ardela mr-3'>{reward.value}</h6>
                </div>)
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  } else {
    return (
      <div className='mt-2 d-flex flex-wrap justify-content-around desktop-list-row'>
        <div class="mt-2 spinner-border color-highlight" role="status">
          <span class="sr-only"></span>
        </div>
      </div>
    )
  }
  ;
}

export default CodesList;